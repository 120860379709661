<template>
  <v-container fluid>
    <section-header
      :title="$t('settingsPage.notifications')"
      :has-action="false"
    />

    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <p class="text-left text-h6">
            {{ $t('notifications.emailNotifications') }}
          </p>

          <div class="d-flex align-center">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.newsEmailList') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newsEmail"
              color="blue"
              inset
              hide-details
              class="mt-0"
            />
          </div>
        </v-col>

        <v-col cols="12" sm="8" offset-sm="2" class="mt-4 text-left">
          <p class="text-h6">
            {{ $t('notifications.systemNotifications') }}
          </p>

          <p class="text-body-1">
            {{ $t('testCases') }}
          </p>

          <div>
            <p class="text-body-2 mb-1">
              {{ $t('notifications.notifyMe') }}
            </p>
            <v-select
              v-model="testCaseNotifyType"
              :items="notifyTypes"
              dense
              filled
              item-text="text"
              item-value="value"
            />
          </div>

          <div class="d-flex">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.testCaseAssignedToMe') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newTestCaseAssignedToMe"
              color="blue"
              inset
              hide-details
              class="mt-0"
            />
          </div>

          <div class="d-flex mt-3">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.newCommentAssignedToMe') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newCommentAssignedToMe"
              color="blue"
              inset
              hide-details
              class="mt-0"
            />
          </div>

          <p class="text-body-1 mt-4">
            {{ $t('testRuns') }}
          </p>

          <div>
            <p class="text-body-2 mb-1">
              {{ $t('notifications.notifyMe') }}
            </p>
            <v-select
              v-model="testRunNotifyType"
              :items="notifyTypes"
              dense
              filled
              item-text="text"
              item-value="value"
            />
          </div>

          <div class="d-flex">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.testRunAssignedToMe') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newTestRunAssignedToMe"
              color="blue"
              inset
              hide-details
              class="mt-0"
            />
          </div>

          <p class="text-body-1 mt-4">
            {{ $t('defects') }}
          </p>

          <div>
            <p class="text-body-2 mb-1">
              {{ $t('notifications.notifyMe') }}
            </p>
            <v-select
              v-model="defectNotifyType"
              :items="notifyTypes"
              dense
              filled
              item-text="text"
              item-value="value"
            />
          </div>

          <div class="d-flex">
            <p class="text-body-2 mb-0">
              {{ $t('notifications.newCommentDefects') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="newCommentDefects"
              color="blue"
              inset
              hide-details
              class="mt-0"
            />
          </div>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="blue"
            width="300px"
            elevation="0"
            class="white--text text-capitalize"
            @click="onUpdateNotifications()"
          >
            {{ $t('notifications.updateNotifications') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import makeUserService from '@/services/api/user';

import SectionHeader from '@/components/Form/SectionHeader.vue';

import { showSuccessToast, showErrorToast } from '@/utils/toast';

export default {
  name: 'Notifications',

  components: {
    SectionHeader,
  },

  data () {
    return {
      notifyTypes: [
        { value: 'platform', text: this.$t('notifications.inPlatformOnly') },
        { value: 'emailPerDay', text: this.$t('notifications.inPlatformEmailPerDay') },
        { value: 'emailImmediate', text: this.$t('notifications.inPlatformEmailImmediate') },
      ],
      newsEmail: true,
      newTestCaseAssignedToMe: true,
      newCommentAssignedToMe: true,
      newTestRunAssignedToMe: true,
      newCommentDefects: true,
      testCaseNotifyType: 'platform',
      testRunNotifyType: 'emailPerDay',
      defectNotifyType: 'emailImmediate',
    }
  },

  computed: {
    ...mapGetters({ currentUser: 'user/user' }),
  },

  mounted() {
    this.newsEmail = this.currentUser.preferences?.newsEmail === undefined ? true : this.currentUser.preferences.newsEmail
    this.newTestCaseAssignedToMe = this.currentUser.preferences?.newTestCaseAssignedToMe === undefined ? true : this.currentUser.preferences.newTestCaseAssignedToMe
    this.newCommentAssignedToMe = this.currentUser.preferences?.newCommentAssignedToMe === undefined ? true : this.currentUser.preferences.newCommentAssignedToMe
    this.newTestRunAssignedToMe = this.currentUser.preferences?.newTestRunAssignedToMe === undefined ? true : this.currentUser.preferences.newTestRunAssignedToMe
    this.newCommentDefects = this.currentUser.preferences?.newCommentDefects === undefined ? true : this.currentUser.preferences.newCommentDefects
    this.testCaseNotifyType = this.currentUser.preferences?.testCaseNotifyType === undefined ? 'platform' : this.currentUser.preferences.testCaseNotifyType
    this.testRunNotifyType = this.currentUser.preferences?.testRunNotifyType === undefined ? 'platform' : this.currentUser.preferences.testRunNotifyType
    this.defectNotifyType = this.currentUser.preferences?.defectNotifyType === undefined ? 'platform' : this.currentUser.preferences.defectNotifyType
  },

  methods: {
    ...mapActions({
      setUser: 'user/setUser',
      setLoading: 'setLoading',
    }),

    async onUpdateNotifications() {
      this.setLoading({
        loading: true,
        loadingText: this.$t('notifications.updatingNotifications')
      })

      const userService = makeUserService(this.$api);

      try {
        const response = await userService.updatePreferences({
          preferences: {
            ...this.currentUser.preferences,
            newsEmail: this.newsEmail,
            newTestCaseAssignedToMe: this.newTestCaseAssignedToMe,
            newCommentAssignedToMe: this.newCommentAssignedToMe,
            newTestRunAssignedToMe: this.newTestRunAssignedToMe,
            newCommentDefects: this.newCommentDefects,
            testCaseNotifyType: this.testCaseNotifyType,
            testRunNotifyType: this.testRunNotifyType,
            defectNotifyType: this.defectNotifyType,
          }
        })

        this.setUser({
          ...this.currentUser,
          preferences: response.data.preferences,
        })

        showSuccessToast(this.$swal, this.$t('notifications.notificationsUpdated'))
      } catch (err) {
        console.log(err)
        showErrorToast(this.$swal, err.response?.data?.message || 'Internal server error')
      } finally {
        this.setLoading({
          loading: false,
        })
      }
    }
  }
}
</script>